.App {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
}

.Content {
  flex: 1 0 auto;
}

.Hover:hover {
  cursor:pointer;
  border-bottom: 1px dashed #425A70;
  background: #DDEBF7;
}

a {text-decoration: none;}